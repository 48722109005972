.contact-div1{
height: 68vh;
  background: linear-gradient(90deg, #171a1f 0%, #08090a 69.62%),
    url(../Images/image\ 13.svg),
    lightgray -32.434px 0px / 102.282% 100% no-repeat;
    margin-bottom: 80px;
    padding-top: 160px;
    position: relative;
    

}

.contact-text1{
    color: #FFF;
font-family: Poppins;
font-size: 82px;
font-style: normal;
font-weight: 700;
line-height: 89px; /* 108.537% */
text-align: center;
}

.contact-truckpic1{
    /* position: absolute;
    right: 0px; */
    margin-top: 50px;
}


.contact-uss-form-div1{
    padding:50px;
}

.contact-uss-input-field1{
    border: none;
    background:transparent;
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-bottom: 1px solid #8C7F7F;
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
}

.contact-uss-input-field1::placeholder{
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.contact-uss-input-field1:focus {
    color: #fff;
    background-color: transparent;
    border-color: #8c7f7f;
    outline: 0;
    box-shadow: none;
}


.contact-uss-input-div2{
    margin-top: 45px;
}





@media screen and (max-width: 576px) {
    .contact-div1 {
    height: 62vh;
    /* background: linear-gradient(90deg, #171a1f 0%, #08090a 69.62%), url(http://localhost:3000/static/media/image%2013.9125a9d….svg), lightgray -32.434px 0px / 102.282% 100% no-repeat; */
    margin-bottom: 0px;
    padding-top: 160px;
    position: relative;
}
    .contact-text1{
    color: #FFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 108.537% */
text-align: center;
}

.contact-truckpic1 {
    /* position: absolute;
    right: 0px; */
    margin-top: 45px;
    width: 120%;
}
.contact-uss-form-div1 {
    padding: 60px 30px;
    background: black;
    margin-bottom: 50px;
}
.contact-uss-input-div2{
    margin-top: 40px;
}
.contact-uss-input-div33{
    margin-top: 40px;
}
}

