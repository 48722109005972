.truck-main-div11122{
    padding-top: 79px;
}

.truck-div1{
/* background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 20.28%, rgba(0, 0, 0, 0.00) 99.99%), url(../Images/Trailer-Truck-Mockup-Free-PSD\ 2.svg), lightgray -132px -254.182px / 125.139% 141.466% no-repeat; */
height: 90vh;
background-image: url(../Images/Trailer-Truck-Mockup-Free-PSD\ 2\ \(2\).svg);
background-repeat: no-repeat;
background-size: cover;
display: flex;
align-items: center;
position: relative;
margin-bottom: 100px;
/* padding-top: 75px; */

}



.trucks-div-11{
    margin-top: -45px;
}


.truck-dispatch-text1{
color: #FFF;
font-family: Poppins;
font-size: 82px;
font-style: normal;
font-weight: 700;
line-height: 89px; /* 108.537% */
}


.truck-dispatch-divtext1{
    margin-top: 45px;
}


.truck-arrow-svg1{
width: 30px;
height: 30px;
flex-shrink: 0;
background: #BE0B31;
display: flex;
align-items: center;
justify-content: center;
}

.truck-arrow-para1{
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 1.1; /* 345% */
margin-left: 25px;
margin-bottom: 0px;
}





.truck-contact-formdiv1 {
  background: #131313;
  padding-top: 60px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 80px;
  position: absolute;
  right: 0px;
}

.truck-contact-us-btn1 {
  width: 100%;
  height: 66px;
  flex-shrink: 0;
  background: #204cf1;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1; /* 345% */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.truck-checkbox-div1 {
  margin-top: 40px;
}







.truck-dispatch-sec2-div1{
padding-top: 80px;
}

.truck-dispatch-sec2-text1{
color: #BE0B31;
/* text-align: center; */
font-family: Poppins;
font-size: 47px;
font-style: normal;
font-weight: 700;
line-height: 1.4; /* 146.809% */
}

.truck-dispatch-sec2-text2{
    color: #000;
/* text-align: center; */
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 1.4; /* 191.667% */
}

.truck-dispatch-sec2-text3{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 1.7; /* 200% */
margin-top: 23px;
}












.truck-dispatch-sec3-div1{
padding-top: 25px;
}

.truck-dispatch-sec3-text1{
color: #000;
text-align: center;
font-family: Poppins;
font-size: 47px;
font-style: normal;
font-weight: 700;
line-height: 69px; /* 146.809% */
}


.truck-dispatch-sec3-div2{
margin-top: 50px;
row-gap: 30px;
}

.truck-dispatch-sec3-btn1{
    width: 100%;
height: 84px;
flex-shrink: 0;
background: #204CF1;
color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}

.truck-dispatch-sec3-btn2{
    width: 100%;
height: 84px;
flex-shrink: 0;
background: #BE0B31;
color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}






.truck-services-div1{
    padding-top: 120px;
    padding-bottom: 90px;
}

.truck-services-text1{
color: #000;
font-family: Poppins;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 129.167% */
}

.truck-services-text2{
    color: #000;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 200% */
margin-top: 25px;
}




.truck-services-text3{
color: #000;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 62px; /* 193.75% */
margin-top: 70px;

}

.truck-services-svg1-div1{
    margin-top: 50px;
}

.truck-services-svg1{
width: 30px;
height: 30px;
flex-shrink: 0;
background: #BE0B31;
display: flex;
align-items: center;
justify-content: center;
}

.truck-services-text4{
    color: #000;
font-family: Poppins;
font-size: 29px;
font-style: normal;
font-weight: 700;
line-height: 36px; /* 124.138% */
margin-bottom: 0px;
margin-left: 20px;
}


.truck-services-para11{
color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 35px;
margin-top: 27px;
}

.truck-services-para11 span{
color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 35px;
margin-top: 27px;
}


.truck-services-para22{
color: #000;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 500;
margin-top: 27px;
/* line-height: 62px; 248% */
}

.truck-services-para122{
color: #000;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
margin-top: 27px;
/* line-height: 62px; 248% */
}

.truck-services-para222{
color: #000;
font-family: Poppins;
font-size: 29px;
font-style: normal;
font-weight: 600;
margin-top: 27px;
/* line-height: 62px; 248% */
}



.truck-services-sec3-div1{
    margin-top: 110px;
}



.faqs-div1{
padding-top: 90px;
}

.faqs-div122{
padding-top: 55px;
}

.faq-text1{
color: #000;
font-family: Poppins;
font-size: 56px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 25px;
}

.faq-main-div2{

}

.faq-line1{
width: 100%;
height: 1px;
background: #909090;
margin-top: 15px;
margin-bottom: 15px;
}

.faq-div3{
align-items: center;
justify-content: space-between;
cursor: pointer;
}

.faq-text3{
color: #000;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 248% */
margin-bottom: 0px;
transition: all 0.5s linear;

}

.faq-text44{
color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: 34px; /* 248% */
margin-bottom: 30px;
margin-top: 24px;
transition: all 0.5s linear;
}

.faq-text44-div1{
transition: all 0.5s linear;

}

.faq-svg-div1{
width: 35px;
height: 35px;
flex-shrink: 0;
background: #BE0B31;
display: flex;
align-items: center;
justify-content: center;
}








/* @media screen and (max-width: 1330px) { */
    .truck-services-img-div11{
    width: 100%;
    height: auto;
    /* margin-top: 35px; */
}
.truck-services-img-div22{
    width: 100%;
    height: auto;
    /* margin-top: 35px; */
}





@media screen and (max-width: 576px) {
    .truck-div1 {
    height: 90vh;
   
    align-items: start;
    margin-bottom: 100px;
    padding-top: 75px;
    padding-left: 20px ;
    padding-right: 20px ;
    
}

.trucks-div-11{
    margin-top: 0px;
    /* margin-bottom: 100px; */
}

.truck-arrow-para1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    margin-left: 25px;

}
    .truck-dispatch-text1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
}

.truck-contact-formdiv1 {
    background: #131313;
    padding-top: 60px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 50px;
    position: relative;
    /* right: 0px; */
    margin-top: 70px;
}

.truck-dispatch-sec2-div1 {
    padding-top: 400px;
}
.truck-dispatch-sec2-text1 {
    font-size: 36px;
    line-height: 1.4;
}
.truck-dispatch-sec2-text2 {
    color: #000;
    font-size: 28px;
    line-height: 1.3;
}
.truck-dispatch-sec3-div1 {
    padding-top: 0px;
}
.truck-dispatch-sec3-text1 {
    font-size: 40px;
    line-height: 58px;
}
.truck-services-div1 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.truck-services-text1 {
    font-size: 32px;
    line-height: 48px;
}
.truck-services-img-div11{
    width: 100%;
    height: auto;
    margin-top: 35px;
}

.truck-services-text3 {
    font-size: 32px;
    line-height: 48px;
    margin-top: 60px;
}

.truck-services-text4 {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 0px;
    margin-left: 20px;
}
.truck-services-para122{
color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
margin-top: 27px;
/* line-height: 62px; 248% */
}

.truck-services-para222{
color: #000;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
margin-top: 27px;
/* line-height: 62px; 248% */
}
.truck-services-svg1{
    margin-top: 8px;
}
.truck-services-para22 {
    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-top: 27px;
}

.truck-services-img-div22{
    width: 100%;
    height: auto;
    margin-top: 35px;
}
.truck-services-sec3-div1 {
    margin-top: 70px;
}
.faq-text1 {
    font-size: 48px;
}
.faq-text3 {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 0px;
}
.faq-div3 {
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}

.truck-services-truck-div1{
    margin-top: 240px;
}
.truck-dispatch-sec3-btn2 {
   
    font-size: 20px;
}
.truck-dispatch-sec3-btn1 {
   
    font-size: 20px;
}
}

