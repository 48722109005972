.navbar-div12{
    background: #000;
    width: 100%;
height: 82px;
flex-shrink: 0;
position: fixed !important;
z-index: 9999;

}


.logo-div1{
    background: #04090D;
    height: 82px;
    width: 330px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.logo-div2{
column-gap: 10px;
}

.logo-div3{

}



.logo-text1{
color: #FFF;
font-family: Helvetica;
font-size: 12.2px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0px;
}

.navbar-ul1{
    column-gap: 18px;
}

.navbar-li1 a{
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.navbar-btn-div2{
    width: 330px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(119, 98, 75, 0.40);
background: #BE0B31;
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


















.mobile-navbar-div1 {
    background-color: #272727 !important;
    min-height: 84px;
    width: 100%;
    position: absolute;
    z-index: 999;
}

@media screen and (max-width: 991px) {
    .navbar-div12{
    
display: none !important;

}

    .mobile-navbar-div1 {
        display: block;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 9;
    }
}

.mobile-nav-toogler {
    border: none;
}

.mobile-nav-item .nav-link {
     color: #FFF !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top: 15px;
    margin-left: 0px !important;
}

/* .mobile-nav-item .nav-link:hover {
     color: #FEAB02 !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
} */

.mobile-navbar-div2 {
    /* background: #EAEFFF; */
    background-color: #272727;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 88px;
    transform: translateX(-110%);
    transition: all 0.5s linear;
    /* box-shadow: 1px 1px 10px rgb(131, 131, 131); */
}

.mobile-navbar-div2.show {
    /* background: #EAEFFF; */
    background-color: #272727;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 90px;
    transition: none;
    transform: translateX(0%) !important;
    transition: all 0.5s linear;
}

/* .mobile-nav-item.active .nav-link {
    color: #FEAB02 !important;
} */








@media screen and (max-width: 1380px) {
    .logo-div1{
    background: #04090D;
    height: 82px;
    width: 280px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.navbar-btn-div2{
    width: 280px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(119, 98, 75, 0.40);
background: #BE0B31;
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.navbar-ul1{
    column-gap: 14px;
}
}

@media screen and (max-width: 1250px) {
    .logo-div1{
    background: #04090D;
    height: 82px;
    width: 235px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.navbar-btn-div2{
    width: 235px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(119, 98, 75, 0.40);
background: #BE0B31;
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.navbar-ul1{
    column-gap: 14px;
}
}






