.footer-div1{
    min-height: 100vh;
    background: #000;
    margin-top: 160px;
    padding-bottom: 70px;
}

.footer-div3{
    padding-top: 60px;
    padding-bottom: 60px;
    background: #235BF0;
    /* margin-top: -70px; */
    transform:translateY(-100px);
}

.footer-text1{
color: #E2E2E2;
font-family: Poppins;
font-size: 23px;
font-style: normal;
font-weight: 500;
line-height: 38px; /* 269.565% */
}

.footer-text2{
color: #FFF;
font-family: Poppins;
font-size: 60px;
font-style: normal;
font-weight: 700;
line-height: 70px; /* 110% */
}

.footer-btn1{
    margin-top: 20px;
    width: 324px;
height: 68px;
flex-shrink: 0;
    background: #002382;
color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}


.footer-truck-img{
    position:absolute;
    top:-200px;
    right:15px;
}





.footer-sec2-div1{
margin-top: -20px;
}

.footer-usa-text1{
color: #FFF;
font-family: Helvetica;
font-size: 24.09px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 20px;
}

.footer-usa-text2{
    color: #FFF;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
}


.footer-sec2-div2{
display: flex;
justify-content: center;
column-gap: 20px;
margin-top: 25px;
}

.footer-usa-text3{
color: #FFF;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.footer-usa-btn1{
    width: 214px;
height: 70px;
flex-shrink: 0;
background: #BE0B31;
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}

.footer-usa-btn2{
    width: 214px;
height: 70px;
flex-shrink: 0;
background: #235BF0;
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}


.footer-usa-btn3{
    width: 214px;
height: 70px;
flex-shrink: 0;
background: #1CA610;
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}


.footer-usa-text44{
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 225% */
margin-top: 25px;
margin-bottom: 20px;
}

.footer-social-media-svg1{
    transform:translateX(11px);
}

.footer-social-media-svg2{
    transform:translateX(-11px);
}









@media screen and (max-width: 576px){

    .footer-div1 {
    min-height: 100vh;
    background: #000;
    margin-top: 220px;
    padding-bottom: 70px;
}

.footer-div2 {
    padding: 0px;
}

.footer-div3 {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #235BF0;
    transform: translateY(-150px);
}

.footer-text1 {
    color: #E2E2E2;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    margin-top: 120px;
}

.footer-text2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
}

.footer-btn1 {
    margin-top: 20px;
    width: 245px;
    height: 66px;
    flex-shrink: 0;
    background: #002382;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-sec2-div2 {
    display: block;
    margin-left: 15px;
}

.footer-sec2-div1 {
    margin-top: -90px;
}

.footer-usa-text1 {
    color: #FFF;
    font-family: Helvetica;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;
}

.footer-usa-btn2 {
   margin-top: 20px;
}

.footer-usa-btn3 {
   margin-top: 20px;
}

.footer-usa-text2 {
    font-size: 20px;
}


}