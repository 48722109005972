.home-gross-div1{
padding-top: 110px;
padding-bottom: 110px;

}

.home-gross-div2{
padding: 55px 100px;
background: #BE0B31;
}

.home-gross-div3{
display: flex;
justify-content: space-between;
}

.home-gross-text1{
color: #FFF;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: start !important;
}

.home-gross-btn1{
    width: 238px;
height: 69px;
flex-shrink: 0;
background: #500313;
display: flex;
align-items: center;
justify-content: center;
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 69px; /* 345% */
justify-self: end;
}

.home-gross-linediv1{
background: #F35879;
margin-top: 50px;
margin-bottom: 50px;
width: 100%;
height: 1px;
}

.home-gross-listsdiv1{
    row-gap: 25px;
}

.home-gross-svgdiv1{
width: 30.272px;
height: 30.272px;
flex-shrink: 0;
background: #FFF;
display: flex;
align-items: center;
justify-content: center;
}

.home-gross-text3{
color: #FFF;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: 1; /* 248% */
margin-bottom: 0px;
margin-left: 20px;
}









@media screen and (max-width: 576px){
   .home-gross-div3 {
    display: block;
    justify-content: space-between;
}
.home-gross-div1{
padding-top: 70px;
padding-bottom: 70px;

}
.home-gross-div2{
padding: 55px 37px;
background: #BE0B31;
}
.home-gross-text1{
font-size: 38px;
line-height: normal;
text-align: start !important;
}

.home-gross-btn1{
    width: 100%;
height: 66px;
font-size: 20px;
line-height: 69px; /* 345% */
justify-self: end;
margin-top: 30px;
}

.home-gross-text3 {
    font-size: 22px;
    margin-left: 20px;
}

.home-gross-listsdiv1 {
    row-gap: 28px;
}


}